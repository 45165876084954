// fal - Light

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPieChart } from '@fortawesome/pro-light-svg-icons';
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock';
import { faAlarmExclamation } from '@fortawesome/pro-light-svg-icons/faAlarmExclamation';
import { faAlarmSnooze } from '@fortawesome/pro-light-svg-icons/faAlarmSnooze';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faArrowRightFromArc } from '@fortawesome/pro-light-svg-icons/faArrowRightFromArc';
import { faArrowRotateLeft } from '@fortawesome/pro-light-svg-icons/faArrowRotateLeft';
import { faArrowsToCircle } from '@fortawesome/pro-light-svg-icons/faArrowsToCircle';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons/faArrowUpRight';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faBellSlash } from '@fortawesome/pro-light-svg-icons/faBellSlash';
import { faBolt } from '@fortawesome/pro-light-svg-icons/faBolt';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen';
import { faBracketsCurly } from '@fortawesome/pro-light-svg-icons/faBracketsCurly';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons/faCaretDown';
import { faChartArea } from '@fortawesome/pro-light-svg-icons/faChartArea';
import { faChartColumn } from '@fortawesome/pro-light-svg-icons/faChartColumn';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faChartScatter } from '@fortawesome/pro-light-svg-icons/faChartScatter';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faChevronDoubleDown } from '@fortawesome/pro-light-svg-icons/faChevronDoubleDown';
import { faChevronDoubleUp } from '@fortawesome/pro-light-svg-icons/faChevronDoubleUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons/faClockRotateLeft';
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons/faCodeBranch';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faColumns } from '@fortawesome/pro-light-svg-icons/faColumns';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faCrosshairsSimple } from '@fortawesome/pro-light-svg-icons/faCrosshairsSimple';
import { faCube } from '@fortawesome/pro-light-svg-icons/faCube';
import { faCut } from '@fortawesome/pro-light-svg-icons/faCut';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faDiagramProject } from '@fortawesome/pro-light-svg-icons/faDiagramProject';
import { faDiamond } from '@fortawesome/pro-light-svg-icons/faDiamond';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand';
import { faExpandAlt } from '@fortawesome/pro-light-svg-icons/faExpandAlt';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons/faFilePlus';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faFontCase } from '@fortawesome/pro-light-svg-icons/faFontCase';
import { faFunction } from '@fortawesome/pro-light-svg-icons/faFunction';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faGripLinesVertical } from '@fortawesome/pro-light-svg-icons/faGripLinesVertical';
import { faHdd } from '@fortawesome/pro-light-svg-icons/faHdd';
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faImageSlash } from '@fortawesome/pro-light-svg-icons/faImageSlash';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faKeyboard } from '@fortawesome/pro-light-svg-icons/faKeyboard';
import { faKeySkeleton } from '@fortawesome/pro-light-svg-icons/faKeySkeleton';
import { faLambda } from '@fortawesome/pro-light-svg-icons/faLambda';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faLayerPlus } from '@fortawesome/pro-light-svg-icons/faLayerPlus';
import { faListOl } from '@fortawesome/pro-light-svg-icons/faListOl';
import { faListTree } from '@fortawesome/pro-light-svg-icons/faListTree';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons/faLockKeyhole';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faMinimize } from '@fortawesome/pro-light-svg-icons/faMinimize';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons/faNoteSticky';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faPause } from '@fortawesome/pro-light-svg-icons/faPause';
import { faPercent } from '@fortawesome/pro-light-svg-icons/faPercent';
import { faPlaneDeparture } from '@fortawesome/pro-light-svg-icons/faPlaneDeparture';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faPlug } from '@fortawesome/pro-light-svg-icons/faPlug';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faPlusHexagon } from '@fortawesome/pro-light-svg-icons/faPlusHexagon';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons/faShareNodes';
import { faSidebarFlip } from '@fortawesome/pro-light-svg-icons/faSidebarFlip';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';
import { faSlidersSimple } from '@fortawesome/pro-light-svg-icons/faSlidersSimple';
import { faSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faSquareTerminal } from '@fortawesome/pro-light-svg-icons/faSquareTerminal';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { faTableColumns } from '@fortawesome/pro-light-svg-icons/faTableColumns';
import { faTerminal } from '@fortawesome/pro-light-svg-icons/faTerminal';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faToggleOn } from '@fortawesome/pro-light-svg-icons/faToggleOn';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faWavePulse } from '@fortawesome/pro-light-svg-icons/faWavePulse';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

library.add(
  faAlarmSnooze,
  faAlarmClock,
  faAlarmExclamation,
  faLockKeyhole,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromArc,
  faArrowRotateLeft,
  faArrowsToCircle,
  faArrowToBottom,
  faArrowUpRight,
  faArrowUpRightFromSquare,
  faBars,
  faBell,
  faBellSlash,
  faBolt,
  faBook,
  faBookmark,
  faBookOpen,
  faBracketsCurly,
  faCalendarAlt,
  faCaretDown,
  faChartArea,
  faChartColumn,
  faChartLine,
  faChartScatter,
  faCheck,
  faCheckCircle,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faClock,
  faClockRotateLeft,
  faCode,
  faCodeBranch,
  faCog,
  faColumns,
  faCopy,
  faCrosshairsSimple,
  faCube,
  faCut,
  faDatabase,
  faDiamond,
  faDownload,
  faDiagramProject,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandAlt,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileCode,
  faFilePlus,
  faFilter,
  faFolder,
  faFontCase,
  faFunction,
  faGlobe,
  faGripLinesVertical,
  faHdd,
  faHistory,
  faHome,
  faInfoCircle,
  faImageSlash,
  faKey,
  faKeyboard,
  faKeySkeleton,
  faLambda,
  faLayerGroup,
  faLayerPlus,
  faListOl,
  faListTree,
  faListUl,
  faMagnifyingGlass,
  faMinimize,
  faMinus,
  faMinusCircle,
  faNoteSticky,
  faPaperclip,
  faPause,
  faPercent,
  faPieChart,
  faPlaneDeparture,
  faPlay,
  faPlug,
  faPlus,
  faPlusHexagon,
  faShareNodes,
  faSidebarFlip,
  faSitemap,
  faSlidersSimple,
  faSparkles,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faSquareTerminal,
  faStar,
  faStopwatch,
  faSync,
  faSyncAlt,
  faTableColumns,
  faTerminal,
  faTimes,
  faTimesCircle,
  faToggleOn,
  faTrash,
  faTrashAlt,
  faUpload,
  faUser,
  faUserGroup,
  faUserCheck,
  faUserPlus,
  faUsers,
  faWavePulse,
  faXmark
);
