import { type QueryPageFormSchema } from '../../routes/query/schema/queryFormSchema';
import { type QueryAplParams, type APLRequestWithOptions, type GetDatasetParams } from '../../shared/dash';
import { orgStore } from '../../stores/OrgStore';
import { type HistorySaveAsKind } from '../../stores/QueryHistoryStore';
import { type StarredSaveAsKind } from '../../stores/SavedQueryStore';
import { type QuickRange } from '../dates';
import { removeUndefinedValues } from '../objects';

import { type QueryForUsers } from './queryOptions';

const org = () => orgStore.activeOrgId;

export const QueryKeys = {
  __TEMPORARY__datasetsHistory__WITH_DIFFERENT_QUERYKEY: ({ qid, sid }: { qid?: string; sid?: string }) => [
    ...QueryKeys.datasetsHistory.get({ qid: qid, sid: sid }),
    '__TEMPORARY_DIFFERENT_QUERYKEY', // add this to the end instead of beginning so it will still invalidate as intended
  ],
  annotations: ({
    dataset,
    timestampStart,
    timestampEnd,
    quickRange,
  }: {
    dataset: string;
    timestampStart?: string | number | undefined;
    timestampEnd?: string | number | undefined;
    quickRange?: QuickRange;
  }) => [
    org(),
    'annotations',
    { dataset: dataset, timestampStart: timestampStart, timestampEnd: timestampEnd, quickRange: quickRange },
  ],
  apl: (query: APLRequestWithOptions, params: QueryAplParams) => [org(), 'apl', query, params],
  aplPreview: (aplQueryRequest: { apl: string; startTime: string; endTime: string }) => [
    org(),
    'apl-preview',
    aplQueryRequest,
  ],
  appViews: (path: string) => [org(), 'appViews', path],
  databaseStats: () => [org(), 'databaseStats'],
  datasets: () => [org(), 'datasets'],
  datasetsExtras: () => [org(), 'datasetsExtras'],
  datasetExtras: (datasetId: string) => [org(), 'datasetExtras', datasetId],
  usage: ({ startTime, endTime }: { startTime: string; endTime: string }) => [org(), 'usage', startTime, endTime],
  getUsage: (orgId: string) => [orgId, 'getUsage'],
  dataset: (params: GetDatasetParams) => [org(), 'dataset', params],
  datasetInfo: (datasetName?: string) => [org(), 'datasetInfo', datasetName],
  datasetVirtualFields: (datasetName?: string) => [org(), 'datasetVirtualFields', datasetName],
  datasetsHistory: {
    base: () => [org(), 'historyQuery'],
    get: (params: { qid?: string; sid?: string; relative?: boolean }) => {
      const cleanedParams = removeUndefinedValues(params);

      return [...QueryKeys.datasetsHistory.base(), cleanedParams];
    },
  },
  downloadTrace: ({
    traceDataset,
    traceId,
    startTime,
    endTime,
  }: {
    traceDataset?: string;
    traceId?: string;
    startTime?: string;
    endTime?: string;
  }) => [
    org(),
    'downloadTrace',
    { traceDataset: traceDataset, traceId: traceId, startTime: startTime, endTime: endTime },
  ],
  flowsDestination: (destinationId: string) => [org(), 'flowsDestination', destinationId],
  flowsDestinationActivity: (destinationId: string) => [org(), 'flowsDestinationActivity', destinationId],
  flowsDestinations: () => [org(), 'flowsDestinations'],
  flowsDestinationStates: () => [org(), 'flowsDestinations', 'states'],
  flows: () => [org(), 'flows'],
  flow: (flowId: string) => [org(), 'flow', flowId],
  maintenance: () => [org(), 'maintenance'],
  notifiers: () => [org(), 'notifiers'],
  notifierById: (id?: string) => [org(), 'notifierById', id],
  monitorsList: () => [org(), 'monitorsList'],
  monitors: () => [org(), 'monitors'],
  monitorById: (id?: string, withoutHistory?: boolean) => [org(), 'monitorById', id, withoutHistory],
  monitorEdit: (id?: string, type?: string) => [org(), 'monitorEdit', id, type],
  monitorHistoryById: ({
    monitorId,
    startTime,
    endTime,
  }: {
    monitorId: string;
    startTime: string;
    endTime: string;
  }) => [org(), 'monitorHistoryById', monitorId, startTime, endTime],
  paginatedQueryHistory: ({
    datasetId,
    qs,
    forUsers,
    kind,
  }: {
    datasetId?: string;
    qs?: string;
    forUsers?: QueryForUsers;
    kind?: HistorySaveAsKind;
  }) => [org(), 'paginatedQueryHistory', datasetId, forUsers, kind, qs],
  paymentMethod: (orgId: string) => [orgId, 'paymentMethod'],
  queryFormQuery: {
    base: () => ['queryFormQuery'],
    get: (params: QueryPageFormSchema & { save?: boolean }, isAgainst?: boolean) => [
      ...QueryKeys.queryFormQuery.base(),
      removeUndefinedValues(params),
      isAgainst,
    ],
  },
  SSEQuery: {
    base: () => ['SSEQuery'],
    get: (params: QueryPageFormSchema, isAgainst?: boolean) => [...QueryKeys.queryFormQuery.base(), params, isAgainst],
  },
  replays: (flowId: string) => [org(), 'replays', flowId],
  refreshLicense: () => ['refreshLicense'],
  sharedRepoTokens: () => [org(), 'sharedRepoTokens'],
  tokenInfo: (token: string) => [org(), 'tokenInfo', token],
  sharedRepos: () => [org(), 'sharedRepos'],
  streams: (flowId: string) => [org(), 'streams', flowId],
  flowReplay: (props: { flowId: string; replayId: string }) => [org(), 'flows', props.flowId, 'replay', props.replayId],
  flowStream: (props: { flowId: string; streamId: string }) => [org(), 'flows', props.flowId, 'stream', props.streamId],
  span: (spanId: string) => [org(), 'span', spanId, 'data'],
  spanDurationTimeseries: ({ spanId }: { spanId: string }) => [org(), 'span', spanId, 'durationTimeseries'],
  savedQueries: {
    root: () => [org(), 'savedQueries'],
    get: (opts: { datasetId?: string; qs?: string; forUsers?: QueryForUsers; kind?: StarredSaveAsKind }) => [
      ...QueryKeys.savedQueries.root(),
      'not-paginated',
      opts,
    ],
    getPaginated: (opts: { datasetId?: string; qs?: string; forUsers?: QueryForUsers; kind?: StarredSaveAsKind }) => [
      ...QueryKeys.savedQueries.root(),
      'paginated',
      opts,
    ],
  },
  subscriptionState: (orgId: string) => [orgId, 'subscriptionState'],
  traceOutline: (params: { traceDataset?: string; traceId?: string; startTime?: string; endTime?: string }) => [
    org(),
    'trace',
    params,
    'outline',
  ],
  userSettings: () => ['userSettings'],
  users: () => [org(), 'users'],
  statusUsage: (orgId: string) => [orgId, 'statusUsage'],
  listIngress: (orgId: string) => [orgId, 'listIngress'],
  dashboards: (params: { withIntegrationDashboards: boolean }) => [org(), 'dashboards', params],
  views: () => [org(), 'views'],
  org: (orgId: string) => ['org', orgId],
  previewSubscriptionProducts: (orgId: string, products: string[]) => [
    orgId,
    'previewSubscriptionProducts',
    [...products].sort(),
  ],
  invoices: (orgId: string) => [orgId, 'invoices'],
};
